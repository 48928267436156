<template>
    <ks-menu class="menu" :default-active="activeNav">
        <div v-if="globalStore.platformVersion === PlatformVersionEnum.OLD" class="publish-contain">
            <ks-button type="primary" @click="toPublish"><k18n>作品发布</k18n></ks-button>
        </div>
        <template v-for="item in menuConfigs" :key="item.key">
            <ks-menu-item v-if="!item.subMenu" :index="item.key" @click="handleMenuClick(item.key)">
                <MenuIcons :item="item" :active="activeNav === item.key" class="mr-8" />
                <template #title>
                    <ks-tooltip :content="$k18n(item?.label)" placement="top">
                        <div class="menu-label">{{ $k18n(item?.label) }}</div>
                    </ks-tooltip>
                </template>
            </ks-menu-item>
            <ks-submenu v-else :key="item.key" :indent="24" :index="item.key">
                <template #title>
                    <MenuIcons :item="item" :active="activeNav === item.key" class="mr-8" />
                    <ks-tooltip :content="$k18n(item?.label)" placement="top">
                        <div class="menu-label">{{ $k18n(item?.label) }}</div>
                    </ks-tooltip>
                </template>
                <ks-menu-item
                    v-for="subItem in item.subMenu"
                    :key="subItem.key"
                    :index="getSubMenuItemKey(item, subItem)"
                    @click="handleMenuClick(getSubMenuItemKey(item, subItem))">
                    <ks-tooltip :content="$k18n(subItem?.label)" placement="top">
                        <div class="menu-label">{{ $k18n(subItem?.label) }}</div>
                    </ks-tooltip>
                </ks-menu-item>
            </ks-submenu>
        </template>
    </ks-menu>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import MenuIcons from './MenuIcons.vue';
import { useGlobalStore } from '@/stores';
import { PlatformVersionEnum } from '@/stores/global';
import { oldMenuKeyMap } from './const';

const globalStore = useGlobalStore();
const router = useRouter();
const route = useRoute();
const activeNav = ref('');
const emit = defineEmits(['click']);
const { $k18n }: any = (getCurrentInstance() as any).appContext.config.globalProperties;

const searchForm = reactive({
    exactText: '',
});
const menuConfigs = computed(() => {
    // const oldMenuKeyMap = [
    //     'production',
    //     'pool',
    //     'poolMangement',
    //     'translateRuleConfig',
    //     'translate',
    //     'translateManage',
    //     'ecommerceMaterialManagement',
    // ];

    const menu = globalStore?.userInfo?.menus?.filter(item => {
        if (globalStore.platformVersion === PlatformVersionEnum.OLD) {
            return oldMenuKeyMap.includes(item.key);
        }
        return !oldMenuKeyMap.includes(item.key);
    });
    return menu || [];
});
function handleMenuClick(path) {
    router.push(`/${path}`);
    emit('click');
}
function getSubMenuItemKey(item, subItem) {
    return `${item.key}/${subItem.key}`;
}
function toPublish() {
    window.open('/publish', '_blank');
}
watch(
    [() => route.path],
    () => {
        const pathname = window.location.pathname;
        activeNav.value = pathname.slice(1);
    },
    { deep: true, immediate: true }
);
</script>

<style scoped lang="scss">
.menu {
    height: 100%;
    .submenu_wrapper {
        :deep(.ks-submenu__title-container) {
            flex: 1;
        }
        .title {
            width: 100%;
            display: flex;
            height: 100%;
            align-items: center;
        }
    }
    .publish-contain {
        text-align: center;
        margin-bottom: 20px;
        margin-top: 24px;
        .ks-button {
            min-width: 133px;
        }
    }
    .menu-label {
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    :deep(.ks-submenu__title-container) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    :deep(.ks-submenu .ks-menu-item) {
        min-width: unset;
        width: 100%;
    }
}
</style>
