<template>
    <Layout />
</template>
<script setup>
import { useRouter, useRoute } from 'vue-router';
import { watch } from 'vue';
import { microApp } from '@kwr/micro';
import Layout from '@/layout/index.vue';
import { setMicroConfig } from './lib/microSetting';
import { useGlobalStore } from '@/stores';
import { PlatformVersionEnum } from '@/stores/global';

const globalStore = useGlobalStore();
const router = useRouter();

watch(
    () => router.currentRoute.value.path,
    (val, oldVal) => {
        if (val?.includes('cutmotionMicro/reactTest') && val !== oldVal && globalStore.versionBtnShow) {
            globalStore.platformVersion = PlatformVersionEnum.NEW;
        }
    },
    { deep: true }
);
</script>
